import React from 'react';
import io from 'socket.io-client';

async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'no-cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return await response.json(); // parses JSON response into native JavaScript objects
}
const url = 'https://chat.tmpk.net/b24/sendMessage';

interface Message {
  name: string;
  text: string
}
interface AuthData {
  cid: string;
}
function App() {
  const socket = React.useRef<typeof io>();
  const [auth, setAuth] = React.useState<AuthData>();
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [textInput, setTextInput] = React.useState<string>('');
  const addMessage = React.useCallback((message) => {
    setMessages([...messages, message]);
  }, [messages]);
  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cid = urlParams.get('cid')
    if (cid) {
      setAuth({
        cid,
      })
      socket.current = io('https://chat.tmpk.net')
      
      const authData = {
        cid,
        // token: '1234'
      }
      socket.current.emit('auth', authData)
    }
  }, []);
  React.useEffect(() => {
    if(socket.current) {
      socket.current.on('msgToClient', addMessage);
    }
    return () => {
      socket.current.off('msgToClient', addMessage);
    }
  }, [addMessage]);

  if (!auth) {
    return <div>Не указан cid в url (Пример: https://chat.tmpk.net/?cid=1111111 )</div>;
  }
  return (
    <div className="App">
      <div>
        <input value={textInput} onChange={(event) => setTextInput(event.target.value)} />
        <button onClick={() => {
          const message = {
            name: auth.cid,
            text: textInput,
          };
          postData(url, {
            id: auth.cid,
            name: auth.cid,
            chatName: auth.cid,
            text: textInput,
          })
          setTextInput('');
          addMessage(message);
        }}>Отправить</button>
      </div>
      <div>
        {messages.map((message) => (
          <div><b>{message.name}</b>: {message.text}</div>
        ))}
      </div>
    </div>
  );
}

export default App;
